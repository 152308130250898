<template>
  <v-app-bar
    app
    color="white"
    elevate-on-scroll
    :class="$vuetify.breakpoint.mobile ? '' : 'd-flex justify-center'"
  >
    <v-app-bar-nav-icon
      :ripple="false"
      class="hidden-md-and-up text-caption"
      plain
      @click.stop="drawerToggle"
    ><v-icon small>menu</v-icon></v-app-bar-nav-icon>

    <v-toolbar-title>
      <v-menu transition="scroll-y-transition" content-class="elevation-0" open-on-hover min-width="100%" max-height="100%" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="showExtBar('category')"
            :ripple="false"
            style="text-transform: none"
            class="hidden-sm-and-down text-caption"
            large
            text
            plain
          >Apple 产品</v-btn>
        </template>
        <v-card>
          <v-container>
            <v-row class="d-flex align-center" no-gutters>
              <v-col cols="2">
                <v-btn
                  :to="{ path: '/apple/product' }"
                  :ripple="false"
                  style="text-transform: none"
                  class="text-h6 black--text"
                  text
                  plain
                >所有产品</v-btn>
              </v-col>
            </v-row>
            <v-row v-for="(item, i) in category" :key="'category_'+ i" style="overflow-y: auto" class="d-flex align-center" no-gutters>
              <v-col cols="2">
                <v-btn
                  :to="{ path: '/product/'+ encodeURIComponent(item.categoryName.toLowerCase()), query: { brandId: item.brandId, firstCategoryId: item.id } }"
                  :ripple="false"
                  style="text-transform: none"
                  class="text-h6 black--text"
                  text
                  plain
                >{{item.categoryName}}</v-btn>
              </v-col>
              <v-col cols="10">
                <v-breadcrumbs
                  :items="item.childrenList.map(children => {
                return {
                  text: children.categoryName,
                  disabled: false,
                  to: { path: '/product/'+ encodeURIComponent(item.categoryName.toLowerCase()) + '/' + encodeURIComponent(children.categoryName.toLowerCase()), query: { brandId: item.brandId, firstCategoryId: item.id, secondCategoryId: children.id }, hash: '#categoryArea' }
                }
              })"
                  divider="."
                >
                  <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                      :disabled="item.disabled"
                      :to="item.to"
                    >
                      <span class="black--text text-caption">{{ item.text }}</span>
                    </v-breadcrumbs-item>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-menu>
      <v-btn v-for="({link, text}, i) in navMenu"
             :key="i"
             :to="link"
             :ripple="false"
             style="text-transform: none"
             class="hidden-sm-and-down text-caption"
             large
             text
             plain
      >{{ text }}</v-btn>
    </v-toolbar-title>

    <v-spacer class="hidden-md-and-up"></v-spacer>

    <v-menu v-model="searchMenu" content-class="elevation-0" transition="scroll-y-transition" min-width="100%" max-height="100%" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon plain small :ripple="false"><v-icon small>search</v-icon></v-btn>
      </template>
      <product-search-bar @Close="searchMenu = false"></product-search-bar>
    </v-menu>

    <v-menu v-model="cartMenu" content-class="elevation-0" transition="scroll-y-transition" open-on-hover min-width="100%" max-height="100%" offset-y :close-on-click="false" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          :value="cartCount"
          overlap
          v-bind="attrs"
          v-on="on"
          :content="cartCount"
        >
          <v-btn v-bind="attrs" v-on="on" icon plain small :ripple="false"><v-icon small>work_outline</v-icon></v-btn>
        </v-badge>
      </template>
      <shopping-cart-card @Close="cartMenu = false"></shopping-cart-card>
    </v-menu>
    <v-menu v-if="!isAuthenticated" content-class="elevation-0" transition="scroll-y-transition" :nudge-width="200" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text plain small class="text-caption"
          :ripple="false"
          v-bind="attrs"
          v-on="on">
          <v-icon left small>perm_identity</v-icon>登录/注册
        </v-btn>
      </template>
      <v-card class="d-flex flex-column justify-start">
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" class="mb-2">
              <span class="text-h6">你好</span>
            </v-col>
            <v-col cols="12">
              <p><router-link :to="{ path: '/login' }">登录</router-link>查看你的订单</p>
            </v-col>
            <v-col cols="12" class="my-2">
              <span class="text-h6">个人中心</span>
            </v-col>
            <v-col cols="12">
              <v-btn
                :to="{ path: '/account' }"
                :ripple="false"
                style="text-transform: none"
                class="black--text pl-0"
                text
                plain
                >注册</v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                :to="{ path: '/login' }"
                :ripple="false"
                style="text-transform: none"
                class="black--text pl-0"
                text
                plain
                >登录</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-menu v-if="isAuthenticated" content-class="elevation-0" transition="scroll-y-transition" :nudge-width="200" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text plain small class="text-caption"
          :ripple="false"
          v-bind="attrs"
          v-on="on">
          {{ currentUserInfo.username }}
          <v-icon>keyboard_arrow_down</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" md="8">
              <span class="text-h6">个人中心</span>
            </v-col>
            <v-col cols="12" md="4" class="text-right">
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-btn
                    :to="{ path: '/user-center' }"
                    :ripple="false"
                    style="text-transform: none"
                    class="black--text pl-0"
                    text
                    plain
                    color="primary"
                    >用户中心</v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    :ripple="false"
                    style="text-transform: none"
                    class="black--text pl-0"
                    text
                    plain
                    color="primary"
                    @click="handleLogout"
                    >退出登录</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12" class="my-4">
              <v-divider />
            </v-col>
            <v-col cols="12" md="12">
              <span class="text-h6">{{ currentUserInfo.username }} @ {{ currentUserInfo.enterpriseFullName }},你好</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-row no-gutters>
                <v-col cols="12" class="my-4">
                  <span class="text-h7">你的订单</span>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    :to="{ path: '/order-list?type=intention' }"
                    :ripple="false"
                    style="text-transform: none"
                    class="black--text pl-0 compact-btn"
                    text
                    plain
                    >意向单</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    :to="{ path: '/order-list?type=purchase_order' }"
                    :ripple="false"
                    style="text-transform: none"
                    class="black--text pl-0 compact-btn"
                    text
                    plain
                    >采购单</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    :to="{ path: '/order-list?type=service_order' }"
                    :ripple="false"
                    style="text-transform: none"
                    class="black--text pl-0  compact-btn"
                    text
                    plain
                    >服务工单</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    :to="{ path: '/order-list?type=repair_order' }"
                    :ripple="false"
                    style="text-transform: none"
                    class="black--text pl-0  compact-btn"
                    text
                    plain
                    >维修工单</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4">
              <v-row no-gutters>
                <v-col cols="12" class="my-4">
                  <span class="text-h7">账户设置</span>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    :to="{ path: '/user-center?type=basic' }"
                    :ripple="false"
                    style="text-transform: none"
                    class="black--text pl-0 compact-btn"
                    text
                    plain
                    >基础信息</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    :to="{ path: '/user-center?type=enterprise' }"
                    :ripple="false"
                    style="text-transform: none"
                    class="black--text pl-0 compact-btn"
                    text
                    plain
                    >企业信息</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    :to="{ path: '/user-center?type=security' }"
                    :ripple="false"
                    style="text-transform: none"
                    class="black--text pl-0 compact-btn"
                    text
                    plain
                    >安全设置</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="serviceList && serviceList.length > 0" cols="12" md="4" class="mb-4">
              <v-row no-gutters>
                <v-col cols="12" class="my-4">
                  <span class="text-h7">服务申报</span>
                </v-col>
                <v-col cols="12" v-for="(s, i) in serviceList" :key="i">
                  <v-btn
                    :ripple="false"
                    style="text-transform: none"
                    class="black--text pl-0 compact-btn"
                    text
                    plain
                    @click="openServiceDialog(s._id)"
                  >{{ s.name }}</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
    <contact-service :showDialog="showServiceDialog" :serviceId="serviceId" @dialogCloseEvent="showServiceDialog = false"></contact-service>
  </v-app-bar>
</template>
<script>
import Util from '@/common/util';
import store from "@/store";
import {
  mapGetters
} from "vuex";
import {
  FETCH_CART_LIST,
  FETCH_CATEGORY_LIST,
  FETCH_SERVICE_LIST, LOGOUT
} from "@/store/actions.type";
export default {
  data() {
    return {
      categoryMenu: false,
      extAppBar: false,
      showServiceDialog: false,
      serviceId: null,
      cartCount: 0,
      cartMenu: false,
      searchMenu: false,
    }
  },
  computed: {
    ...mapGetters(['category', 'isAuthenticated', 'currentUserInfo', 'serviceList', 'cart_list']),
    navMenu () {
      return Util.categories('navMenu')
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      store.dispatch(FETCH_CATEGORY_LIST);
      if (this.isAuthenticated) {
        store.dispatch(FETCH_SERVICE_LIST);
        store.dispatch(FETCH_CART_LIST, '')
      }
    },
    drawerToggle() {
      window.getApp.$emit("APP_DRAWER_TOGGLED");
    },
    showExtBar() {
      this.extAppBar = true
    },
    closeExtBar() {
      this.extAppBar = false
      this.$forceUpdate()
    },
    openServiceDialog(id) {
      this.serviceId = id;
      this.showServiceDialog = true;
    },
    handleLogout() {
      this.cartCount = 0
      store.dispatch(LOGOUT)
        .then(() => {
          this.$router.push('/login');
        })
    }
  },
  watch: {
    isAuthenticated(newVal, oldVal) {
      if (newVal) {
        console.log('isAuthenticated');

        store.dispatch(FETCH_SERVICE_LIST);
        store.dispatch(FETCH_CART_LIST, '')
          .then((data) => {
            this.cartCount = data.result.cartProductList.length || 0
          })
      }
      this.$forceUpdate()
    },
    'cart_list': {
      handler: function (newVal, oldVal) {
        this.cartCount = newVal.cartProductList.length || 0
        this.$forceUpdate()
      },
      deep: true
    }
  },
}
</script>

<style scoped>
.text-h7 {
  font-size: 15px;
  font-weight: 500;
}
.compact-btn {
  height: 28px !important;
}
</style>
