<template>
  <v-dialog 
    v-model="dialogVisible" 
    content-class="my-custom-dialog" 
    :width="$vuetify.breakpoint.mobile ? '' : '600'" 
    :height="$vuetify.breakpoint.mobile ? '' : '800'" 
    scrollable 
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card class="aiCard">
      <v-card-text class="closePanel">
        <v-btn
          class="closeBtn"
          text
          @click="handleClose"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-text>
      <iframe 
        id="frame" 
        class="aiFrame"
        style="width: 100%; border: 0px; margin:0;" 
        :src="aiUrl">
      </iframe>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialogVisible'],
  data () {
    return {
      aiUrl: process.env.VUE_APP_AI_URL,
    }
  },
  methods: {
    handleClose() {
      this.$emit('dialogVisibleEvent', false);
    }
  },
  mounted() {
    const that = this;
    window.addEventListener('message',function(event){
      if (event.data === 'contact') {
        //弹出留资对话框
        that.$emit('openContactEvent');
      }
    })
  },
  watch: {
    // showDialog(val) {
    //   this.dialogVisible = val;
    // }
  },
  // beforeCreate() {
  //   console.log("子组件————beforeCreate...");
  // },
  // created() {
  //   console.log("子组件————create...");
  // },
  // beforeMount() {
  //   console.log("子组件————beforeMount...");
  // },
  // mounted() {
  //   console.log("子组件————mounted...");
  // },
  // beforeUpdate() {
  //   console.log("子组件————beforeUpdate...");
  // },
  // updated() {
  //   console.log("子组件————updated...");
  // },
  // beforeDestroy() {
  //   console.log("子组件————beforeDestroy...");
  // },
  // destroyed() {
  //   console.log("子组件————destroyed...");
  // },
}
</script>
<style scoped>
.my-custom-dialog {
  bottom: 0 !important;
  right: 0 !important;
  position: absolute !important;
}
.aiFrame {
  width: 100%;
  height: 100%;
  border: 0px;
  margin:0;
}

.closePanel {
  margin: 0;
  padding: 0 !important;
  height: 36px;
  overflow: hidden;
  overflow-y: hidden !important;
}

.closeBtn {
  float: right;
  padding: 0;
  color: #000 !important;
}

.aiCard {
  height: 700px;
}
@media screen and (max-width:600px){
  .aiCard {
    height: 100%;
  }
}
</style>
