<template>
  <v-app>
    <core-head />
    <core-topbar />
    <core-drawer />
    <core-view />
    <core-footer />
    <contact-fab />
    <widgets-error />
    <widgets-update-message></widgets-update-message>
  </v-app>
</template>
<script>
import { start } from '@/common/operationlog'
export default {
  computed: {
  },
  mounted() {
    //用户埋点：启动
    start()
  },
  created () {
    window.getApp = this;
  },
  destroyed() {
  },
}

</script>
<style lang="scss">
  .v-application {
    font-family: "SF Pro SC", "SF Pro Text", "SF Pro Icons", "PingFang SC", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
    .text-h1,
    .text-h2,
    .text-h3,
    .text-h4,
    .text-h5,
    .text-h6,
    .text-headline,
    .text-title,
    .text-subtitle-1,
    .text-subtitle-2,
    .text-body-1,
    .text-body-2,
    .text-button,
    .text-caption,
    .text-overline {
      font-family: "SF Pro SC", "SF Pro Text", "SF Pro Icons", "PingFang SC", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
    }
  }
  #nprogress .bar {
    background: #0071e3 !important;
  }
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
</style>

