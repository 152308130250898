import {CHANGE_THEME} from "./actions.type";
import {SET_THEME} from "./mutations.type";

const state = {
  dark: localStorage.getItem('aapl_dark') === 'true'
};

const getters = {
  darkTheme: state => state.dark
};

const actions = {
  [CHANGE_THEME](context, credentials) {
    context.commit(SET_THEME, credentials);
  }
};

const mutations = {
  [SET_THEME](state, data) {
    state.dark = data;
    localStorage.setItem('aapl_dark', JSON.stringify(data))
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
