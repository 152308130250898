import { AppService } from "@/common/http";

import {
  FETCH_CATEGORY_LIST,
  FETCH_AREA_LIST,
  UPLOAD,
  FETCH_BANNER_LIST,
  SEARCH_KNOWLEDGE,
  FETCH_KNOWLEDGE_DETAIL,
  FETCH_KNOWLEDGE_LIST,
} from "./actions.type";

import {
  SET_ERROR,
  SET_CATEGORY_LIST,
  SET_BANNER_LIST
} from "./mutations.type"

const state = {
  category: [],
  banners: [],
};

const getters = {
  category: state => state.category,
  banners: state => state.banners
};

const actions = {
  [FETCH_CATEGORY_LIST]({ commit }) {
    return new Promise((resolve, reject) => {
      AppService.get('category/row/findListByBrandType/APPLE')
        .then(({ data }) => {
          commit(SET_CATEGORY_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_AREA_LIST]() {
    return AppService.get("city/row/list");
  },
  [UPLOAD](context, param, config) {
    return AppService.post("sys/common/upload", param, config);
  },
  [FETCH_BANNER_LIST]({ commit }) {
    return new Promise((resolve, reject) => {
      AppService.get('pageManagement/row/findBannerConfig')
        .then(({ data }) => {
          commit(SET_BANNER_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [SEARCH_KNOWLEDGE](context, param) {
    return AppService.query("esp/knowledgeSearch", param);
  },
  [FETCH_KNOWLEDGE_DETAIL](context, slug) {
    return AppService.get("esp/knowledgeById/", slug);
  },
  [FETCH_KNOWLEDGE_LIST](context, param) {
    return AppService.query("esp/knowledgeList", param);
  },
}

const mutations = {
  [SET_CATEGORY_LIST](state, data) {
    state.category = data.result;
  },
  [SET_BANNER_LIST](state, data) {
    state.banners = data.result;
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};
