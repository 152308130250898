var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-footer', {
    attrs: {
      "color": "secondary",
      "dark": ""
    }
  }, [_c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mb-4",
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/logoword.svg",
      "contain": "",
      "max-height": "40",
      "max-width": _vm.$vuetify.breakpoint.mobile ? '100' : ''
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex justify-lg-space-between",
    attrs: {
      "cols": "12",
      "md": "9",
      "lg": "9"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "",
      "lg": ""
    }
  }, [_c('ul', [_c('li', {
    staticClass: "caption font-weight-bold"
  }, [_vm._v("Apple 产品")]), _vm._l(_vm.category, function (item, i) {
    return _c('li', {
      key: 'category_' + i,
      staticClass: "caption hidden-xs-only"
    }, [_vm._v(_vm._s(item.categoryName))]);
  })], 2)]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "",
      "lg": ""
    }
  }, [_c('ul', [_c('li', {
    staticClass: "caption font-weight-bold"
  }, [_vm._v("业务生态")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("ESC 企业解决方案中心")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("ESP 企业服务平台")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("EC 企业员工优选商城")])])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "",
      "lg": ""
    }
  }, [_c('ul', [_c('li', {
    staticClass: "caption font-weight-bold"
  }, [_vm._v("设备租赁")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("MDaaS 租赁服务")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("客户案例介绍")])])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "",
      "lg": ""
    }
  }, [_c('ul', [_c('li', {
    staticClass: "caption font-weight-bold"
  }, [_vm._v("解决方案")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("MDM 移动设备管理")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("桌面虚拟化")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("零信任安全")])])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "",
      "lg": ""
    }
  }, [_c('ul', [_c('li', {
    staticClass: "caption font-weight-bold"
  }, [_vm._v("售后和管理")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("运营维护")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("设备部署")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("检测维修")])])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "",
      "lg": ""
    }
  }, [_c('ul', [_c('li', {
    staticClass: "caption font-weight-bold"
  }, [_vm._v("成功案例")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("金融")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("航空")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("医药")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("互联网")])])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "",
      "lg": ""
    }
  }, [_c('ul', [_c('li', {
    staticClass: "caption font-weight-bold"
  }, [_vm._v("活动和培训")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("Apple 培训")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("Jamf 培训")]), _c('li', {
    staticClass: "caption hidden-xs-only"
  }, [_vm._v("市场活动")])])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    staticClass: "white--text caption",
    attrs: {
      "href": "https://www.miit.gov.cn/",
      "target": "_blank"
    }
  }, [_vm._v("京ICP备13003150号-3")]), _c('p', {
    staticClass: "caption"
  }, [_vm._v("© 2023-" + _vm._s(new Date().getFullYear()) + " 倍升互联（北京）科技有限公司")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "0",
      "md": "1",
      "lg": "1"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }