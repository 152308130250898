import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/common/jwt'

const API_URL = process.env.VUE_APP_BASE_API || 'localhost:9091'

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = JwtService.getToken();
    Vue.axios.defaults.headers.common[
      "x-access-token"
    ] = JwtService.getToken();
  },
  query(resource, params) {
    let url = resource;
    if (params != null) {
      url += "?";
      for (let key in params) {
        url += key + "=" + params[key] + "&";
      }
    }
    return Vue.axios.get(url, params);
  },
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}${slug}`);
  },
  post(resource, params, config) {
    return Vue.axios.post(`${resource}`, params, config);
  },
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },
  delete(resource, params) {
    return Vue.axios.delete(resource, params);
  },
  download(resource, config){
    return Vue.axios.get(resource, config);
  }
};
export default ApiService;

export const AppService = {
  get(resource, slug) {
    return ApiService.get('/double-rise/'+ resource, slug);
  },
  query(resource, params) {
    return ApiService.query('/double-rise/'+ resource, params);
  },
  getAuth(resource, slug) {
    ApiService.setHeader();
    return ApiService.get('/double-rise/'+ resource, slug);
  },
  queryAuth(resource, params) {
    ApiService.setHeader();
    return ApiService.query('/double-rise/'+ resource, params);
  },
  post(resource, params, config) {
    return ApiService.post('/double-rise/'+ resource, params, config);
  },
  postAuth(resource, params) {
    ApiService.setHeader();
    return ApiService.post('/double-rise/'+ resource, params);
  },
  create(resource, params) {
    return ApiService.post('/double-rise/'+ resource, params);
  },
  put(resource, params) {
    return ApiService.put('/double-rise/'+ resource, params);
  },
}

//商品服务
export const ProductService = {
  get(resource, slug) {
    return ApiService.get('/double-rise/'+ resource, slug);
  },
  post(resource, params, config) {
    return ApiService.post('/double-rise/'+ resource, params, config);
  },
  create(resource, params) {
    return ApiService.post('/double-rise/'+ resource, params);
  },
}
//购物车管理
export const CartService = {
  get(resource, slug) {
    return ApiService.get('/double-rise/'+ resource, slug);
  },
  post(resource, params, config) {
    return ApiService.post('/double-rise/'+ resource, params, config);
  },
  create(resource, params) {
    return ApiService.post('/double-rise/'+ resource, params);
  },
}



//产品目录，头部菜单：https://api_aapl.doublerise.com/double-rise/category/row/findListByBrandType/APPLE
//首页Banner：https://api_aapl.doublerise.com/double-rise/pageManagement/row/findBannerConfig
//留咨、留言POST：https://api_aapl.doublerise.com/double-rise/consultation/row/insert
//省市三级注册用：https://api_aapl.doublerise.com/double-rise/city/row/list
//登录POST：https://api_aapl.doublerise.com/double-rise/sys/login
//企业服务目录：https://api_aapl.doublerise.com/double-rise/esp/enterpriseServiceList
