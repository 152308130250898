export const categories = (target) => {
  let objTarget = {}, dataTime = new Date()
  switch (target) {
    case 'navMenu':
      objTarget = [
        {link: '/sales-support', text: '销售支持'},
        {link: '/equipment-lease', text: '设备租赁'},
        {link: '/solution', text: '解决方案'},
        {link: '/after-sales', text: '售后和管理'},
        {link: '/success-cases', text: '成功案例'},
        {link: '/activity', text: '活动培训'}
      ]
      break
    case 'updateTime': {
      objTarget = [
        {name: '1天内', code: dataTime.setDate(dataTime.getDate() - 1)},
        {name: '1周内', code: dataTime.setDate(dataTime.getDate() - 7)},
        {name: '2周内', code: dataTime.setDate(dataTime.getDate() - 14)},
        {name: '1个月内', code: dataTime.setDate(dataTime.getDate() - 30)},
      ]
      break
    }
    case 'leaseMethod': {
      objTarget = [
        {name: '月付', code: '月付'},
        {name: '季付', code: '季付'}
      ]
      break
    }
    case 'leasePeriod': {
      objTarget = [
        {name: '1年', code: 12},
        {name: '2年', code: 24},
        {name: '3年', code: 36}
      ]
      break
    }
    case 'repairStatus':
      objTarget = [
        {name: '待受理', code: '0'},
        {name: '检测中/维修中', code: '1'},
        {name: '维修完成', code: '2'},
        {name: '已返回/已取机', code: '3'},
        {name: '服务结束', code: '4'},
        {name: '取消维修', code: '8'},
      ]
      break
    case 'orderStatus':
      objTarget = [
        {name: '已接单', code: '0'},
        {name: '处理中', code: '1'},
        {name: '已取消', code: '2'},
        {name: '已拒绝', code: '3'},
        {name: '已完成', code: '4'},
      ]
      break
    case 'purchaseStatus':
      objTarget = [
        {name: '待处理', code: '0'},
        {name: '处理中', code: '1'},
        {name: '已取消', code: '2'},
        {name: '已完成', code: '4'},
      ]
      break
  }
  return objTarget
};

export default {
  categories,
};
