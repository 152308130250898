<template>
  <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
    有新的功能调整。
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" text @click="refreshApp">
        更新
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import update from '@/common/swupdate'
export default {
  mixins: [update],
}
</script>
