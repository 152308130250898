var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    attrs: {
      "app": ""
    },
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list', {
    attrs: {
      "dense": "",
      "nav": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "link": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', {
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/'
        });
      }
    }
  }, [_vm._v("首页")])], 1)], 1), _c('v-list-item', {
    attrs: {
      "link": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', {
    on: {
      "click": function click($event) {
        _vm.drawerProduct = true;
      }
    }
  }, [_vm._v("Apple 产品")])], 1)], 1), _vm._l(_vm.navMenu, function (_ref, i) {
    var link = _ref.link,
      text = _ref.text;
    return _c('v-list-item', {
      key: i,
      attrs: {
        "link": "",
        "to": link
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(text))])], 1)], 1);
  })], 2), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": "",
      "hide-overlay": "",
      "width": "100%"
    },
    model: {
      value: _vm.drawerProduct,
      callback: function callback($$v) {
        _vm.drawerProduct = $$v;
      },
      expression: "drawerProduct"
    }
  }, [_c('v-list', {
    attrs: {
      "dense": "",
      "nav": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" 选择产品 ")]), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.drawerProduct = !_vm.drawerProduct;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-divider'), _vm._l(_vm.category, function (item, i) {
    return _c('v-list-item', {
      key: 'category_' + i,
      attrs: {
        "link": "",
        "to": {
          path: '/product/' + encodeURIComponent(item.categoryName.toLowerCase()),
          query: {
            brandId: item.brandId,
            firstCategoryId: item.id
          }
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.categoryName))])], 1)], 1);
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }