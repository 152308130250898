import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export const webRouter = [
  {
    path: '/',
    hidden: false,
    name: 'home',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/index'
    ),
    meta: {
      login_required: false,
      operation_name: 'HOME_PAGE',
    }
  },
  {
    path: '/product/:brand',
    hidden: false,
    name: 'product_brand',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/product/list.vue'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'PRODUCT_BRAND_PAGE',
    }
  },
  {
    path: '/product/:brand/:category',
    hidden: false,
    name: 'product_category',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/product/list.vue'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'PRODUCT_CATEGORY_PAGE',
    }
  },
  {
    path: '/product/:brand/:category/:product',
    hidden: false,
    name: 'product_detail',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/product/detail.vue'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'PRODUCT_CATEGORY_DETAIL',
    }
  },
  {
    path: '/apple/product',
    hidden: false,
    name: 'product_index',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/product/index.vue'
      ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'PRODUCT_INDEX_PAGE',
    }
  },
  {
    path: '/shopping/checkout',
    hidden: true,
    name: 'checkout',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/shopping/checkout.vue'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'SHOPPING_CHECKOUT',
    }
  },
  {
    path: '/lease',
    hidden: false,
    name: 'lease',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/lease'
    ),
    meta: {
      login_required: false,
      operation_name: 'LEASE_INDEX_PAGE',
    }
  },
  {
    /* 登录 */
    path: '/login',
    hidden: false,
    name: 'login',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/login/index'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'LOGIN_PAGE',
    }
  },
  {
    /* 注册 */
    path: '/account',
    hidden: false,
    name: 'account',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/register/index'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'REGISTER_PAGE',
    }
  },
  {
    /* 用户中心 */
    path: '/user-center',
    hidden: false,
    name: 'user-center',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/user-center/index'
    ),
    props: true,
    meta: {
      login_required: true,
      operation_name: 'USER_CENTER_PAGE',
    }
  },
  {
    /* 订单列表 */
    path: '/order-list',
    hidden: false,
    name: 'order-list',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/order-list/index'
    ),
    props: true,
    meta: {
      login_required: true,
      operation_name: 'ORDER_LIST_PAGE',
    }
  },
  {
    /* 获取支持 */
    path: '/get-support',
    hidden: false,
    name: 'get-support',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/get-support/index'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'GET_SUPPORT_PAGE',
    }
  },
  {
    path: '/get-support/detail/:id/',
    hidden: false,
    name: 'get-support-detail',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/get-support/detail.vue'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'GET_SUPPORT_DETAIL',
    }
  },
  {
    /* Mac as Choice */
    path: '/mac-as-choice',
    hidden: false,
    name: 'mac-as-choice',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/mac-as-choice/index.vue'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'MAC_AS_CHOICE_PAGE',
    }
  },
  {
    /* 销售支持 */
    path: '/sales-support',
    hidden: false,
    name: 'sales-support',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/sales-support/index'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'SALES_SUPPORT_PAGE',
    }
  },
  {
    /* 设备租赁 */
    path: '/equipment-lease',
    hidden: false,
    name: 'equipment-lease',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/equipment-lease/index'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'EQUIPMENT_LEASE_PAGE',
    }
  },
  {
    /* 解决方案 */
    path: '/solution',
    hidden: false,
    name: 'solution',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/solution/index'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'SOLUTION_PAGE',
    }
  },
  {
    /* 售后和管理 */
    path: '/after-sales',
    hidden: false,
    name: 'after-sales',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/after-sales/index'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'AFTER_SALES_PAGE',
    }
  },
  {
    /* 成功案例 */
    path: '/success-cases',
    hidden: false,
    name: 'success-cases',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/success-cases/index'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'SUCCESS_CASES_PAGE',
    }
  },
  {
    /* 活动培训 */
    path: '/activity',
    hidden: false,
    name: 'activity',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/activity/index'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'ACTIVITY_PAGE',
    }
  },
  {
    /* 隐私协议 */
    path: '/privacy',
    hidden: false,
    name: 'privacy',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/privacy/index'
    ),
    props: true,
    meta: {
      login_required: false,
      operation_name: 'PRIVACY_PAGE',
    }
  },
  {
    path: '/account/setting',
    name: 'Account_Setting',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/account/setting'
    ),
    meta: {
      login_required: true,
      operation_name: 'ACCOUNT_SETTING_PAGE',
    },
    props: true,
    children: [
      {
        path: 'info',
        name: 'Account_Setting_Info',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/account/setting/info'
        ),
        meta: {
          login_required: true,
          operation_name: 'ACCOUNT_INFO_PAGE',
        },
      },
      {
        path: 'secure',
        name: 'Account_Setting_Secure',
        hidden: true,
        component: () => import(
          /* webpackChunkName: "routes" */
          '@/views/account/setting/secure'
        ),
        meta: {
          login_required: true,
          operation_name: 'ACCOUNT_SECURE_PAGE',
        },
      }
    ]
  }
]

export default new Router({
  base: "/",
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      const position = { x: 0, y: 0 }
      if (to.hash) {
        position.selector = to.hash
      }
      return position
    }
  },
  routes: webRouter
});
