var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-carousel', {
    attrs: {
      "delimiter-icon": "remove",
      "reverse-transition": "fade-transition",
      "transition": "fade-transition",
      "show-arrows": !_vm.$vuetify.breakpoint.mobile,
      "height": _vm.$vuetify.breakpoint.mobile ? '215px' : '575px'
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  }, _vm._l(_vm.list, function (item, i) {
    return _c('v-carousel-item', {
      key: 'banners_' + i,
      attrs: {
        "src": item.url
      }
    });
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }