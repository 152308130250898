<template>
  <div style="position: fixed; bottom: 40px; right: 40px; z-index: 100;">
    <v-fab-transition>
      <v-btn
        color="green"
        dark
        fab
        small
        v-log="'CLICK_OPEN_FAB_CHAT'"
        @click="showAiDialog(true)"
        >
        <img width="73px" src="/img/icons/ai-icon.png" />
      </v-btn>
    </v-fab-transition>
  <aichat-dialog :dialogVisible="aiDialog" @dialogVisibleEvent="showAiDialog" @openContactEvent="contactDialog = true"></aichat-dialog>
  <contact-dialog :showDialog="contactDialog" type="VISION_PRO_BOOKING" @dialogCloseEvent="contactDialog = false"></contact-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    fab: false,
    aiDialog: false,
    contactDialog: false,
  }),
  methods: {
    showAiDialog(show) {
      this.aiDialog = show;
    }
  },
  watch: {},
}
</script>
