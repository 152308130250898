<template>
    <v-card>
      <v-container>
        <v-card-title>购物袋<v-spacer></v-spacer><v-btn icon @click="closeCard()"><v-icon>close</v-icon></v-btn></v-card-title>
        <v-card-text v-if="isAuthenticated"  style="height: 50%;">
          <v-row v-for="(item, index) in cart_list.cartProductList" :key="'cart_card_'+ index">
            <v-col cols="12" md="2" lg="2"><v-img contain :src="item.skuList[0].picUrlList[0]" max-width="150px" max-height="150px"></v-img></v-col>
            <v-col cols="12" md="5" lg="5">
              <p class="col-12 text-truncate text-h6 ml-n3">{{item.skuList[0].skuName}}</p>
              <v-chip v-if="item.purchaseType === 0">{{item.leasePayMethod}}</v-chip>
              <v-chip v-if="item.purchaseType === 0" class="mx-2">{{ item.leasePeriod }}月</v-chip>
            </v-col>
            <v-col cols="12" md="2" lg="2">
              <v-text-field
                v-model="item.count"
                type="number"
                :value="item.count"
                :min="0"
                prepend-inner-icon="remove"
                append-icon="add"
                style="max-width: 100px"
                @change="setProductCount('change', index)"
                @click:append="setProductCount('add', index)"
                @click:prepend-inner="item.count > 1 ? setProductCount('remove', index) : ''"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" lg="3" class="text-end">
              <p v-if="item.purchaseType === 1" class="text-h6">RMB {{item.skuList[0].enterpriseSalesPrice * item.count | formatMoney}} </p>
              <p v-else class="text-h6">RMB {{item.skuList[0].enterpriseLeasePrice * item.count | formatMoney}}/月</p>
              <v-btn plain :ripple="false" color="primary" @click="removeProduct(item.cartId)">移除</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>无数据</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col cols="12" md="6" lg="6">
              <p class="subtitle-2">您的购物袋共计 {{cart_list.productCount}} 件商品，总计 RMB {{cart_list.priceTotal | formatMoney}}</p>
            </v-col>
            <v-col cols="12" md="6" lg="6" class="text-end">
              <v-btn :block="$vuetify.breakpoint.mobile" depressed rounded color="primary" @click="toCheck()">去结算</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-container>
    </v-card>
</template>
<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {
  FETCH_CART_LIST,
  DEL_PROD_CART,
  ADD_PROD_CART
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'cart_list']),
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      if (this.isAuthenticated) store.dispatch(FETCH_CART_LIST, '')
    },
    removeProduct(id) {
      store.dispatch(DEL_PROD_CART, id)
        .then(() => {
          store.dispatch(FETCH_CART_LIST, '')
        })
    },
    setProductCount(operate, index) {
      const objProduct = this.cart_list.cartProductList[index]
      const objOperate = {
        "purchaseType": objProduct.purchaseType,
        "operateType": null,
        "count": 1,
        "skuId": objProduct.skuList[0].id,
        "spuId": objProduct.id,
        "leasePayMethod": objProduct?.leasePayMethod || null,
        "leasePeriod": objProduct?.leasePeriod || null
      }
      if (operate === 'add') {
        objOperate.operateType = 'ADD'
        this.cart_list.cartProductList[index].count++
      }
      if (operate === 'remove') {
        objOperate.operateType = 'SUBTRACT'
        this.cart_list.cartProductList[index].count--
      }
      if (operate === 'change') {
        if (this.cart_list.cartProductList[index].count > 0) {
          objOperate.operateType = 'SET'
          objOperate.count =+ this.cart_list.cartProductList[index].count
        }
      }
      if (objOperate.operateType) {
        store.dispatch(ADD_PROD_CART, objOperate)
          .then(() => {
            this.initialize()
          })
      } else {
        store.commit(SET_ERROR, {msg: '请输入0以上数字', color: 'primary'});
      }

    },
    closeCard() {
      this.$emit('Close')
    },
    toCheck() {
      this.$router.push({ path: '/shopping/checkout' });
      this.closeCard()
    }
  }
}
</script>
