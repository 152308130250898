import { AppService } from "@/common/http";

import {
  PUBLISH_OPERATION_LOGS
} from "./actions.type";

const state = { };
const getters = { };

const actions = {
  [PUBLISH_OPERATION_LOGS](context, data) {
    return AppService.create('visitLog/batch', data);
  },
}

const mutations = { }

export default {
  state,
  actions,
  mutations,
  getters
};
