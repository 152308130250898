import { AppService } from "@/common/http";

import {
  PUBLISH_CONTACTUS,
  FETCH_SERVICE_LIST,
  SERVIE_DECLARATION
} from "./actions.type";
import {
  SET_ERROR,
  SET_SERVICE_LIST
} from "./mutations.type"

const state = {
  serviceList: []
};
const getters = {
  serviceList: state => state.serviceList,
};

const actions = {
  [PUBLISH_CONTACTUS](context, data) {
    return AppService.create('consultation/row/insert', data);
  },
  [FETCH_SERVICE_LIST]({ commit }) {
    return new Promise((resolve, reject) => {
      AppService.getAuth('esp/enterpriseServiceList', '')
        .then(({ data }) => {
          commit(SET_SERVICE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [SERVIE_DECLARATION](context, data) {
    return AppService.create('esp/caseCollect', data);
  },
}

const mutations = {
  [SET_SERVICE_LIST](state, data) {
    state.serviceList = data.result || [];
  },
}

export default {
  state,
  actions,
  mutations,
  getters
};
