<template>
  <v-navigation-drawer v-model="drawer" app>
    <v-list dense nav>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title @click="$router.push({path: '/'})">首页</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title @click="drawerProduct = true">Apple 产品</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link :to="link" :key="i" v-for="({link, text}, i) in navMenu">
        <v-list-item-content>
          <v-list-item-title>{{ text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-navigation-drawer
      v-model="drawerProduct"
      app
      right
      hide-overlay
      width="100%"
    >
      <v-list dense nav>
        <v-list-item>
          <v-list-item-title>
            选择产品
          </v-list-item-title>
          <v-list-item-action>
            <v-btn icon @click="drawerProduct = !drawerProduct"><v-icon>close</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link v-for="(item, i) in category" :key="'category_'+ i" :to="{ path: '/product/'+ encodeURIComponent(item.categoryName.toLowerCase()), query: { brandId: item.brandId, firstCategoryId: item.id } }">
          <v-list-item-content>
            <v-list-item-title>{{ item.categoryName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-navigation-drawer>
</template>

<script>
import Util from "@/common/util";
import {mapGetters} from "vuex";

export default {
  data: () => ({
    drawer: false,
    drawerProduct: false
  }),
  computed: {
    ...mapGetters(['category']),
    navMenu () {
      return Util.categories('navMenu')
    },
  },
  created () {
    window.getApp.$on('APP_DRAWER_TOGGLED', () => {
      this.drawer = (!this.drawer);
    });
    window.getApp.$on('APP_DRAWER_HIDE', () => {
      if (this.drawer) {
        this.drawer = false;
      }
    });
    window.getApp.$on('APP_DRAWER_MINI', () => {
      this.mini = true;
    })
  },
}
</script>
