<template>
  <v-carousel
    v-model="model"
    delimiter-icon="remove"
    reverse-transition="fade-transition"
    transition="fade-transition"
    :show-arrows="!$vuetify.breakpoint.mobile"
    :height="$vuetify.breakpoint.mobile ? '215px' : '575px'"
  >
    <v-carousel-item
      v-for="(item, i) in list"
      :key="'banners_'+ i"
      :src="item.url"
    >
    </v-carousel-item>
  </v-carousel>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    model: 0,
  }),
}
</script>
