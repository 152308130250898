<template>
    <v-card>
      <v-container>
        <v-autocomplete
          v-model="searchKey"
          :items="searchList"
          label="查找商品"
          prepend-icon="search"
          item-text="name"
          item-value="to"
          @change="goto()"
        >
        </v-autocomplete>
      </v-container>
    </v-card>
</template>
<script>
import {
  mapGetters
} from "vuex";

export default {
  data() {
    return {
      searchKey:[]
    }
  },
  computed: {
    ...mapGetters(['category']),
    searchList() {
      let arrList = []
      for (const category of this.category) {
        for (const product of category.childrenList) {
          arrList.push({
            name: product.categoryName,
            to: { path: '/product/'+ encodeURIComponent(category.categoryName.toLowerCase()) + '/' + encodeURIComponent(product.categoryName.toLowerCase()), query: { brandId: category.brandId, firstCategoryId: category.id, secondCategoryId: product.id }, hash: '#categoryArea' }
          })
        }
      }
      return arrList
    }
  },
  created() {},
  methods: {
    goto() {
      this.$router.push(this.searchKey)
      this.$emit('Close')
    }
  }
}
</script>
