import {CartService} from "@/common/http";

import {
  FETCH_CART_LIST,
  ADD_PROD_CART,
  DEL_PROD_CART,
  CREATE_NEW_ORDER
} from "./actions.type";

import {
  SET_ERROR,
  SET_CART_LIST,
} from "./mutations.type"

const state = {
  cart_list: [],
};

const getters = {
  cart_list: state => state.cart_list
};

const actions = {
  [FETCH_CART_LIST]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      CartService.get('cart/queryInfo'+ slug)
        .then(({ data }) => {
          commit(SET_CART_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [ADD_PROD_CART]({ commit }, data) {
    return new Promise((resolve, reject) => {
      CartService.post('cart/operate', data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [DEL_PROD_CART]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      CartService.get('cart/remove/'+ slug)
        .then(({ data }) => {
          commit(SET_CART_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [CREATE_NEW_ORDER]({ commit }, data) {
    return new Promise((resolve, reject) => {
      CartService.post('order/create', data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
}

const mutations = {
  [SET_CART_LIST](state, data) {
    state.cart_list = data.result;
  },
}

export default {
  state,
  actions,
  mutations,
  getters
};
