import { AppService } from "@/common/http";

import {
  FETCH_INTENT_LIST,
  FETCH_ORDER_LIST,
  FETCH_SERVICE_ORDER_LIST,
  FETCH_REPAIR_ORDER_LIST,
  FETCH_ORDER_DETAIL,
  FETCH_REPAIR_DETAIL,
  FETCH_JUMP_TOKEN,
  SEARCH_CASE_COLLECT,
} from "./actions.type";

const state = {
};
const getters = { };

const actions = {
  [FETCH_INTENT_LIST](context, params) {
    return AppService.postAuth("order/orderPage", params);
  },
  [FETCH_ORDER_LIST](context, params) {
    return AppService.queryAuth("esp/orderExternal/list", params);
  },
  [FETCH_ORDER_DETAIL](context, slug) {
    return AppService.getAuth("esp/orderExternal/", slug);
  },
  [FETCH_SERVICE_ORDER_LIST](context, params) {
    return AppService.queryAuth("esp/serviceInfoList", params);
  },
  [FETCH_REPAIR_ORDER_LIST](context, params) {
    return AppService.queryAuth("esp/repairList", params);
  },
  [FETCH_REPAIR_DETAIL](context, slug) {
    return AppService.getAuth("esp/repairOverview/", slug);
  },
  [FETCH_JUMP_TOKEN]() {
    return AppService.queryAuth("esp/getJumpToken", null);
  },
  [SEARCH_CASE_COLLECT](context, param) {
    return AppService.query("esp/caseCollectSearch", param);
  },
}

const mutations = {
}

export default {
  state,
  actions,
  mutations,
  getters
};
