var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-container', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.searchList,
      "label": "查找商品",
      "prepend-icon": "search",
      "item-text": "name",
      "item-value": "to"
    },
    on: {
      "change": function change($event) {
        return _vm.goto();
      }
    },
    model: {
      value: _vm.searchKey,
      callback: function callback($$v) {
        _vm.searchKey = $$v;
      },
      expression: "searchKey"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }