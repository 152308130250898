// AUTH
export const SET_AUTH = "setAuth";
export const SET_USER = "setUser";
export const SET_USERINFO = "setUserInfo";
export const PURGE_AUTH = "logOut";
export const SET_ERROR = "setError";

// APP
export const SET_CATEGORY_LIST = "setCategoryList";
export const SET_BANNER_LIST = 'setBannerList'

// THEME
export const SET_THEME = 'setTheme';

// 企业服务列表
export const SET_SERVICE_LIST = "setServiceList";

// 商品服务
export const SET_PROD_FILTER_LIST = 'setFilterListByProduct'
export const SET_PROD_PRICE_LIMIT = 'setPriceLimitByProduct'
export const SET_PROD_LIST = 'setProductList'
export const SET_PROD = 'setProductDetail'
export const SET_PROD_BRAND = 'setProductInfoForBrand'

//购物及交易
export const SET_CART_LIST = 'setCartList'
export const SET_CART = 'setCart'

