import ApiService from "@/common/http";
import JwtService from "@/common/jwt";
import SocketService from '@/common/socket';
import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
  REGISTER,
  FETCH_USER_INFO,
  EDIT_USER,
  CHANGE_PASSWORD
} from "./actions.type";
import { SET_AUTH, SET_USER, SET_USERINFO, PURGE_AUTH, SET_ERROR } from "./mutations.type";

const USER_INFO_KEY = "userInfo";

const state = {
  user: {},
  employer: {},
  isAuthenticated: !!JwtService.getToken(),
  routes: [],
};

const getters = {
  currentUser: state => state.user,
  currentUserInfo: () => {
    const userInfo = window.localStorage.getItem(USER_INFO_KEY);
    if (userInfo === undefined || userInfo === null) {
      return null;
    }
    return JSON.parse(userInfo);
  },
  currentUserName: state => state.userInfo.username,
  currentEmployer: state => state.employer,
  isAuthenticated: state => state.isAuthenticated,
  currentRoutes: state => state.routes,
};

const actions = {
  [LOGIN](context, param) {
    return new Promise((resolve, reject) => {
      // let form = new FormData();
      // form.append('username', credentials.username);
      // form.append('password', credentials.password);
      // let param = new URLSearchParams(form);

      ApiService.post("double-rise/sys/login", param)
        .then(({ data }) => {
          if (data.code !== 200) {
            context.commit(SET_ERROR, { msg: data.message });
            reject(data);
            return;
          }
          context.commit(SET_AUTH, data.result.token);
          context.commit(SET_USERINFO, data.result.userInfo);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [REGISTER](context, param) {
    return ApiService.put("double-rise/enterpriseUser/register", param);

    // return new Promise((resolve, reject) => {
    //   AppService.put("enterpriseUser/register", param);
    //     .then(({ data }) => {
    //       if (data.code !== 200) {
    //         context.commit(SET_ERROR, { msg: data.message });
    //         reject(data);
    //         return;
    //       }
    //       context.commit(SET_AUTH, data.result.token);
    //       resolve(data);
    //     })
    //     .catch(({ response }) => {
    //       reject(response.data);
    //     });
    // });
  },
  [CHECK_AUTH](context) {
    return new Promise((resolve, reject) => {
      if (JwtService.getToken()) {
        // ApiService.setHeader();
        // ApiService.get("double-rise/auth/mine")
        //   .then(({ data }) => {
        //     context.commit(SET_USER, data);
        //     resolve(data)
        //   })
        //   .catch(({ response }) => {
        //     context.commit(SET_ERROR, response.data.message);
        //     reject(response.data.message)
        //   });
        resolve(getters.currentUserInfo());
      } else {
        context.commit(PURGE_AUTH);
        reject({message:'token is null'})
      }
    })
  },
  [LOGOUT](context) {
    return new Promise(resolve => {
      // context.commit(SET_LOGS, { operator: state.user._id, action: 'Logout', objType: 'Account', objId: [state.user._id] });
      context.commit(PURGE_AUTH)
      resolve()
    })
  },
  [FETCH_USER_INFO](context, params) {
    return ApiService.get("double-rise/enterpriseUser/queryById", params);
  },
  [EDIT_USER](context, param) {
    return ApiService.post("double-rise/enterpriseUser/edit", param);
  },
  [CHANGE_PASSWORD](context, param) {
    return ApiService.post("double-rise/enterpriseUser/updatePassword", param);
  },
};

const mutations = {
  [SET_AUTH](state, token) {
    state.isAuthenticated = true;
    JwtService.saveToken(token);

  },
  [SET_USER](state, data) {
    let arrPermission = [];
    data.employer.roles.forEach(item => {
      arrPermission.push(item.permission)
    })
    state.user = Object.assign(data, { permission: [...new Set(arrPermission.flat())] });
    state.employer = data.employer;
  },
  [SET_USERINFO](state, data) {
    window.localStorage.setItem(USER_INFO_KEY, JSON.stringify(data));
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = null;
    state.employer = null;
    JwtService.destroyToken();
    SocketService.disconnect();
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
