var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "content-class": "my-custom-dialog",
      "width": _vm.$vuetify.breakpoint.mobile ? '' : '700',
      "fullscreen": _vm.$vuetify.breakpoint.mobile
    },
    on: {
      "click:outside": _vm.handleClose
    },
    model: {
      value: _vm.showDialog,
      callback: function callback($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mb-4"
  }, [_vm._v(" 申报服务 "), _vm.serviceId ? _c('span', [_vm._v(" - " + _vm._s(_vm.currentServiceName))]) : _vm._e()]), _vm.$vuetify.breakpoint.mobile ? _c('v-card-text', {
    staticClass: "closePanel"
  }, [_c('v-btn', {
    staticClass: "closeBtn",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.handleClose
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1) : _vm._e(), _c('v-card-text', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.validForm,
      callback: function callback($$v) {
        _vm.validForm = $$v;
      },
      expression: "validForm"
    }
  }, [_c('v-row', [!_vm.serviceId ? _c('v-col', {
    class: ['my-0', 'pb-0', _vm.$vuetify.breakpoint.mobile ? '' : 'text-right'],
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('label', [_c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")]), _vm._v("服务类型")])]) : _vm._e(), !_vm.serviceId ? _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-select', {
    attrs: {
      "name": "serviceId",
      "items": _vm.serviceList,
      "item-text": "name",
      "item-value": "_id",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.serviceForm.serviceId,
      callback: function callback($$v) {
        _vm.$set(_vm.serviceForm, "serviceId", $$v);
      },
      expression: "serviceForm.serviceId"
    }
  })], 1) : _vm._e(), _c('v-col', {
    class: ['my-0', 'pb-0', _vm.$vuetify.breakpoint.mobile ? '' : 'text-right'],
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('label', [_c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")]), _vm._v("联系人姓名")])]), _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "contactName",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.serviceForm.contactName,
      callback: function callback($$v) {
        _vm.$set(_vm.serviceForm, "contactName", $$v);
      },
      expression: "serviceForm.contactName"
    }
  })], 1), _c('v-col', {
    class: ['my-0', 'pb-0', _vm.$vuetify.breakpoint.mobile ? '' : 'text-right'],
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('label', [_c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")]), _vm._v("联系人电话")])]), _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "contactPhone",
      "rules": [_vm.rules.required, _vm.rules.phonenum],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.serviceForm.contactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.serviceForm, "contactPhone", $$v);
      },
      expression: "serviceForm.contactPhone"
    }
  })], 1), _c('v-col', {
    class: ['my-0', 'pb-0', _vm.$vuetify.breakpoint.mobile ? '' : 'text-right'],
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('label', [_c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")]), _vm._v("联系人地址")])]), _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "contactAddress",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.serviceForm.contactAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.serviceForm, "contactAddress", $$v);
      },
      expression: "serviceForm.contactAddress"
    }
  })], 1), _vm.isRepair ? _c('v-col', {
    class: ['my-0', 'pb-0', _vm.$vuetify.breakpoint.mobile ? '' : 'text-right'],
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('label', [_c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")]), _vm._v("寄送⽅式")])]) : _vm._e(), _vm.isRepair ? _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-select', {
    attrs: {
      "name": "recoveryChannel",
      "rules": [_vm.rules.required],
      "items": _vm.mailSends,
      "item-text": "name",
      "item-value": "type",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.serviceForm.recoveryChannel,
      callback: function callback($$v) {
        _vm.$set(_vm.serviceForm, "recoveryChannel", $$v);
      },
      expression: "serviceForm.recoveryChannel"
    }
  })], 1) : _vm._e(), _vm.isRepair ? _c('v-col', {
    class: ['my-0', 'pb-0', _vm.$vuetify.breakpoint.mobile ? '' : 'text-right'],
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('label', [_c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")]), _vm._v("寄回联系⼈姓名")])]) : _vm._e(), _vm.isRepair ? _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "returnsPerson",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.serviceForm.returnsPerson,
      callback: function callback($$v) {
        _vm.$set(_vm.serviceForm, "returnsPerson", $$v);
      },
      expression: "serviceForm.returnsPerson"
    }
  })], 1) : _vm._e(), _vm.isRepair ? _c('v-col', {
    class: ['my-0', 'pb-0', _vm.$vuetify.breakpoint.mobile ? '' : 'text-right'],
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('label', [_c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")]), _vm._v("寄回联系⼈电话")])]) : _vm._e(), _vm.isRepair ? _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "returnsPhone",
      "rules": [_vm.rules.required, _vm.rules.phonenum],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.serviceForm.returnsPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.serviceForm, "returnsPhone", $$v);
      },
      expression: "serviceForm.returnsPhone"
    }
  })], 1) : _vm._e(), _vm.isRepair ? _c('v-col', {
    class: ['my-0', 'pb-0', _vm.$vuetify.breakpoint.mobile ? '' : 'text-right'],
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('label', [_c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")]), _vm._v("寄回⽅式")])]) : _vm._e(), _vm.isRepair ? _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-select', {
    attrs: {
      "name": "returnsChannel",
      "rules": [_vm.rules.required],
      "items": _vm.mailBacks,
      "item-text": "name",
      "item-value": "type",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.serviceForm.returnsChannel,
      callback: function callback($$v) {
        _vm.$set(_vm.serviceForm, "returnsChannel", $$v);
      },
      expression: "serviceForm.returnsChannel"
    }
  })], 1) : _vm._e(), _vm.isRepair ? _c('v-col', {
    class: ['my-0', 'pb-0', _vm.$vuetify.breakpoint.mobile ? '' : 'text-right'],
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('label', [_c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")]), _vm._v("寄回地址")])]) : _vm._e(), _vm.isRepair ? _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "returnsAddress",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.serviceForm.returnsAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.serviceForm, "returnsAddress", $$v);
      },
      expression: "serviceForm.returnsAddress"
    }
  })], 1) : _vm._e(), _vm.isRepair ? _c('v-col', {
    class: ['my-0', 'pb-0', _vm.$vuetify.breakpoint.mobile ? '' : 'text-right'],
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('label', [_c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")]), _vm._v("问题描述")])]) : _vm._e(), _vm.isRepair ? _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "name": "detailFault",
      "rules": [_vm.rules.required],
      "rows": "3"
    },
    model: {
      value: _vm.serviceForm.detailFault,
      callback: function callback($$v) {
        _vm.$set(_vm.serviceForm, "detailFault", $$v);
      },
      expression: "serviceForm.detailFault"
    }
  })], 1) : _vm._e(), _c('v-col', {
    class: ['my-0', 'pb-0', _vm.$vuetify.breakpoint.mobile ? '' : 'text-right'],
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('label', [_vm._v("备注")])]), _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "name": "remark",
      "rows": "3"
    },
    model: {
      value: _vm.serviceForm.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.serviceForm, "remark", $$v);
      },
      expression: "serviceForm.remark"
    }
  })], 1), _c('v-col', {
    class: ['my-0', 'pb-0', _vm.$vuetify.breakpoint.mobile ? '' : 'text-right'],
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('label', [_vm._v("附件")])]), _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "filePanel v-text-field d-flex justify-space-between justify-center",
    on: {
      "click": _vm.handleFileSelect
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.uploadName))]), _c('v-btn', {
    attrs: {
      "depressed": "",
      "small": ""
    },
    on: {
      "click": _vm.handleFileSelect
    }
  }, [_vm._v(_vm._s(_vm.uploadBtn))]), _c('input', {
    ref: "uploadFile",
    attrs: {
      "type": "file",
      "hidden": ""
    },
    on: {
      "change": _vm.handleUpload
    }
  })], 1)]), _c('v-col', {
    class: ['my-0', 'pb-0', _vm.$vuetify.breakpoint.mobile ? '' : 'text-right'],
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('label', [_c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")]), _vm._v("序列号")])]), _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.serviceForm.serialNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.serviceForm, "serialNumber", $$v);
      },
      expression: "serviceForm.serialNumber"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "12",
      "offset-md": "3",
      "md": "9"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提交")]), _vm.$vuetify.breakpoint.mobile ? _c('v-btn', {
    staticClass: "ml-4",
    on: {
      "click": _vm.handleClose
    }
  }, [_vm._v("取消")]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }