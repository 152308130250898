import Vue from "vue";
import m from 'moment'
m.locale('zh-cn'); // 使用中文
// Filter - FormatTime
// DateTime > '2019/1/31 00:00:00'
Vue.filter('formatTime', function(val) {
  let time = val;
  if (isNaN(Date.parse(val))) {
    if (val) time = val.replace(/-/g, '/').replace(/T/g, ' ');
  }

  let date = new Date(time);
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  const t1 = [year, month, day].map(formatNumber).join('/')
  const t2 = [hour, minute, second].map(formatNumber).join(':')

  return `${t1} ${t2}`
});
// Filter - fromDate
// String > 4 年前
Vue.filter('fromDate', function (val) {
  let date = new Date(val);
  return m(date).fromNow();
});

// Filter - FormatDate
// Date > 'YYYY-MM-DD'
Vue.filter('formatDate', function (val) {
  if (val) {
    let value = new Date(val);
    let year = value.getFullYear();
    let month = padDate(value.getMonth() + 1);
    let day = padDate(value.getDate());
    return year +'-'+ month +'-'+ day;
  }
});

function padDate (va) {
  va = va < 10 ? '0'+va : va;
  return va;
}
function formatNumber(n) {
  const str = n.toString()
  return str[1] ? str : `0${str}`
}

/**
 * @description 格式化金额
 * @param number：要格式化的数字
 * @param decimals：保留几位小数 默认0位
 * @param decPoint：小数点符号 默认.
 * @param thousandsSep：千分位符号 默认为,
 */
Vue.filter('formatMoney', function (value, str) {
  if (!str) str = '';
  // str 规定货币类型
  if (value == "0") return '0.00';
  if(value == ".") return '';
  if (!value) return '';
  let val = Number(value).toFixed(2) // 提前保留两位小数
  let intPart = parseInt(val) // 获取整数部分
  let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断 ？？？
  let floatPart = '.00' // 预定义小数部分
  val = val.toString() // 将number类型转为字符串类型，方便操作
  let value2Array = val.split('.')
  if (value2Array.length === 2) { // =2表示数据有小数位
    floatPart = value2Array[1].toString() // 拿到小数部分
    if (floatPart.length === 1) { // 补0,实际上用不着
      return str + intPartFormat + '.' + floatPart + '0'
    } else {
      return str + intPartFormat + '.' + floatPart
    }
  } else {
    return str + intPartFormat + floatPart
  }
})
// 100.1234
Vue.filter('formatMoneyDecimal', function (value, str) {
  if (!str) str = '';
  // str 规定货币类型
  if (value == "0") return '0.0000';
  if (value == ".") return '';
  if (!value) return '';
  let val = Number(value).toFixed(4) // 提前保留两位小数
  let intPart = parseInt(val) // 获取整数部分
  let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断 ？？？
  let floatPart = '.0000' // 预定义小数部分
  val = val.toString() // 将number类型转为字符串类型，方便操作
  let value2Array = val.split('.')
  if (value2Array.length === 2) { // =2表示数据有小数位
    floatPart = value2Array[1].toString() // 拿到小数部分
    if (floatPart.length === 1) { // 补0,实际上用不着
      return str + intPartFormat + '.' + floatPart + '0'
    } else {
      return str + intPartFormat + '.' + floatPart
    }
  } else {
    return str + intPartFormat + floatPart
  }
})

//15亿6204万5698.88
Vue.filter('formatMoneyUnitFloat', function (val) {
  // 防止空字符返回为0
  if (val === '' || !val) return val
  let toNumberVal = Number(val)
  if (isNaN(toNumberVal)) {
    return val
  }
  let flag = ''
  if (toNumberVal < 0) {
    flag = '-'
    toNumberVal = Math.abs(toNumberVal)
  }
  if (toNumberVal >= 10000) {
    // 大于等于10000的数据要去掉小数位
    const numStrArr = String(toNumberVal).split('.')
    const numStr = numStrArr[0]
    const len = numStr.length
    const numArr = []
    // 每4位截取一次，截取小于10000部分的数据
    let itemLast = Number(numStr.substring(len - 4, len))
    // 只有个位数前面要加0 等于0则去掉，不加入数组拼接
    if (itemLast < 10 && itemLast > 0) {
      numArr.unshift('0' + itemLast)
    } else if (itemLast >= 10) {
      numArr.unshift(itemLast)
    }
    let itemCenter = 0
    let itemFirst = 0
    if (len <= 8) {
      // 长度小于等于8， 没有亿位，这个时候万位可以从index = 0 开始截取
      itemCenter = Number(numStr.substring(0, len - 4))
    } else {
      // 长度大于8才有亿， 万位不能再从index = 0开始截取
      itemCenter = Number(numStr.substring(len - 8, len - 4))
      itemFirst = Number(numStr.substring(0, len - 8))
    }
    // 万位和亿位如果大于0才加入数组拼接
    if (itemCenter > 0) numArr.unshift(itemCenter + '万')
    if (itemFirst > 0) numArr.unshift(itemFirst + '亿')
    return flag + numArr.join('') + (numStrArr[1] ? `.${numStrArr[1]}` : '')
  }
  return flag + toNumberVal
})

//15亿6204万5698
Vue.filter('formatMoneyUnitInt', function (val) {
  // 防止空字符返回为0
  if (val === '') return val
  let toNumberVal = Number(val)
  if (isNaN(toNumberVal)) {
    return val
  }
  let flag = ''
  if (toNumberVal < 0) {
    flag = '-'
    toNumberVal = Math.abs(toNumberVal)
  }
  if (toNumberVal >= 10000) {
    // 大于等于10000的数据要去掉小数位
    const numStr = Math.floor(toNumberVal) + ''
    const len = numStr.length
    const numArr = []
    // 每4位截取一次，截取小于10000部分的数据
    let itemLast = Number(numStr.substring(len - 4, len))
    // 只有个位数前面要加0 等于0则去掉，不加入数组拼接
    if (itemLast < 10 && itemLast > 0) {
      numArr.unshift('0' + itemLast)
    } else if (itemLast >= 10) {
      numArr.unshift(itemLast)
    }
    let itemCenter = 0
    let itemFirst = 0
    if (len <= 8) {
      // 长度小于等于8， 没有亿位，这个时候万位可以从index = 0 开始截取
      itemCenter = Number(numStr.substring(0, len - 4))
    } else {
      // 长度大于8才有亿， 万位不能再从index = 0开始截取
      itemCenter = Number(numStr.substring(len - 8, len - 4))
      itemFirst = Number(numStr.substring(0, len - 8))
    }
    // 万位和亿位如果大于0才加入数组拼接
    if (itemCenter > 0) numArr.unshift(itemCenter + '万')
    if (itemFirst > 0) numArr.unshift(itemFirst + '亿')
    return flag + numArr.join('')
  }
  return flag + toNumberVal
})

//15亿6204万5698
Vue.filter('formatMoneyUnit', function (val) {
  // 防止空字符返回为0
  if (val === '') return val
  let toNumberVal = Number(val)
  if (isNaN(toNumberVal)) {
    return val
  }
  let flag = ''
  if (toNumberVal < 0) {
    flag = '-'
    toNumberVal = Math.abs(toNumberVal)
  }
  if (toNumberVal >= 10000) {
    // 大于等于10000的数据要去掉小数位
    const numStr = Math.floor(toNumberVal) + ''
    const len = numStr.length
    const numArr = []
    // 每4位截取一次，截取小于10000部分的数据
    let itemLast = Number(numStr.substring(len - 4, len))
    // 只有个位数前面要加0 等于0则去掉，不加入数组拼接
    if (itemLast < 10 && itemLast > 0) {
      numArr.unshift('0' + itemLast)
    } else if (itemLast >= 10) {
      numArr.unshift(itemLast)
    }
    let itemCenter = 0
    let itemFirst = 0
    if (len <= 8) {
      // 长度小于等于8， 没有亿位，这个时候万位可以从index = 0 开始截取
      itemCenter = Number(numStr.substring(0, len - 4))
    } else {
      // 长度大于8才有亿， 万位不能再从index = 0开始截取
      itemCenter = Number(numStr.substring(len - 8, len - 4))
      itemFirst = Number(numStr.substring(0, len - 8))
    }
    // 万位和亿位如果大于0才加入数组拼接
    if (itemCenter > 0) numArr.unshift(itemCenter + '万')
    if (itemFirst > 0) numArr.unshift(itemFirst + '亿')
    return flag + numArr.join('')
  }
  return flag + toNumberVal
})
