var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "fixed",
      "bottom": "40px",
      "right": "40px",
      "z-index": "100"
    }
  }, [_c('v-fab-transition', [_c('v-btn', {
    directives: [{
      name: "log",
      rawName: "v-log",
      value: 'CLICK_OPEN_FAB_CHAT',
      expression: "'CLICK_OPEN_FAB_CHAT'"
    }],
    attrs: {
      "color": "green",
      "dark": "",
      "fab": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.showAiDialog(true);
      }
    }
  }, [_c('img', {
    attrs: {
      "width": "73px",
      "src": "/img/icons/ai-icon.png"
    }
  })])], 1), _c('aichat-dialog', {
    attrs: {
      "dialogVisible": _vm.aiDialog
    },
    on: {
      "dialogVisibleEvent": _vm.showAiDialog,
      "openContactEvent": function openContactEvent($event) {
        _vm.contactDialog = true;
      }
    }
  }), _c('contact-dialog', {
    attrs: {
      "showDialog": _vm.contactDialog,
      "type": "VISION_PRO_BOOKING"
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.contactDialog = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }