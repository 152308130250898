import Vue from "vue";
import Vuex from "vuex";
import app from "./app.module";
import auth from "./auth.module";
import contactus from "./contactus.module";
import error from "./error.module";
import operationlog from "./operationlog.module";
import product from "./product.module";
import shopping from './shopping.module';
import theme from './theme.module';
import order from './order.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app, auth, contactus, error, operationlog, product, order, shopping, theme
  }
});
