var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "content-class": "my-custom-dialog",
      "fullscreen": true
    },
    on: {
      "click:outside": _vm.handleClose
    },
    model: {
      value: _vm.showDialog,
      callback: function callback($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-toolbar', [_c('v-toolbar-title', [_vm._v(" " + _vm._s(_vm.detail.caseNumber) + " "), _vm.detail.status == 0 ? _c('v-chip', {
    attrs: {
      "label": "",
      "text-color": "white",
      "color": "#000"
    }
  }, [_vm._v("待执⾏")]) : _vm._e(), _vm.detail.status == 1 ? _c('v-chip', {
    attrs: {
      "label": "",
      "text-color": "white",
      "color": "#1890FF"
    }
  }, [_vm._v("处理中")]) : _vm._e(), _vm.detail.status == 2 ? _c('v-chip', {
    attrs: {
      "label": "",
      "text-color": "white",
      "color": "#ccc"
    }
  }, [_vm._v("已取消")]) : _vm._e(), _vm.detail.status == 3 ? _c('v-chip', {
    attrs: {
      "label": "",
      "text-color": "white",
      "color": "#f50"
    }
  }, [_vm._v("已拒绝")]) : _vm._e(), _vm.detail.status == 4 ? _c('v-chip', {
    attrs: {
      "label": "",
      "text-color": "white",
      "color": "#87d068"
    }
  }, [_vm._v("已完成")]) : _vm._e()], 1), _c('v-spacer'), _c('v-toolbar-items', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.handleClose
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-card-text', {
    staticClass: "mt-12"
  }, [_c('v-expansion-panels', {
    attrs: {
      "accordion": "",
      "multiple": ""
    },
    model: {
      value: _vm.panels,
      callback: function callback($$v) {
        _vm.panels = $$v;
      },
      expression: "panels"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("基本信息")]), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_vm._v(" PO号 ")]), _c('v-col', {
    attrs: {
      "cols": "8",
      "md": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.detail.poNumber) + " ")]), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_vm._v(" DT号 ")]), _c('v-col', {
    attrs: {
      "cols": "8",
      "md": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.detail.outNumber) + " ")]), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_vm._v(" 订单编号 ")]), _c('v-col', {
    attrs: {
      "cols": "8",
      "md": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.detail.orderNumber) + " ")]), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_vm._v(" 客户 ")]), _c('v-col', {
    attrs: {
      "cols": "8",
      "md": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.detail.enterprise.name) + " ")]), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_vm._v(" 创建人 ")]), _c('v-col', {
    attrs: {
      "cols": "8",
      "md": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.detail.creator.personal.name) + " ")]), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_vm._v(" 创建时间 ")]), _c('v-col', {
    attrs: {
      "cols": "8",
      "md": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.detail.createTime) + " ")]), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_vm._v(" 发票信息 ")]), _c('v-col', {
    attrs: {
      "cols": "8",
      "md": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.invoiceType) + " ")]), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_vm._v(" 收货信息 ")]), _c('v-col', {
    attrs: {
      "cols": "8",
      "md": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.detail.deliveryInfo.address) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.detail.deliveryInfo.phone) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.detail.deliveryInfo.name) + " ")])], 1)], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("详细信息")]), _c('v-expansion-panel-content', [_c('v-data-table', {
    staticClass: "orderTable",
    attrs: {
      "headers": _vm.detailHeaders,
      "items": _vm.detail.productList,
      "items-per-page": 100,
      "hide-default-footer": "",
      "no-data-text": "暂无数据"
    }
  }), _c('div', {
    staticClass: "ml-4 mt-4",
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("数量合计"), _c('span', {
    staticClass: "ml-6"
  }, [_vm._v(_vm._s(_vm.productCount))])])], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("相关工单")]), _c('v-expansion-panel-content', [_c('v-data-table', {
    staticClass: "orderTable",
    attrs: {
      "headers": _vm.caseHeaders,
      "items": _vm.detail.caseList,
      "items-per-page": 100,
      "hide-default-footer": "",
      "no-data-text": "暂无数据"
    },
    scopedSlots: _vm._u([{
      key: "item.status",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.status == 0 ? _c('span', {
          staticStyle: {
            "color": "#000"
          }
        }, [_vm._v("待执⾏")]) : _vm._e(), item.status == 1 ? _c('span', {
          staticStyle: {
            "color": "#1890FF"
          }
        }, [_vm._v("处理中")]) : _vm._e(), item.status == 2 ? _c('span', {
          staticStyle: {
            "color": "#ccc"
          }
        }, [_vm._v("已取消")]) : _vm._e(), item.status == 3 ? _c('span', {
          staticStyle: {
            "color": "#f50"
          }
        }, [_vm._v("已拒绝")]) : _vm._e(), item.status == 4 ? _c('span', {
          staticStyle: {
            "color": "#87d068"
          }
        }, [_vm._v("已完成")]) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }