<template>
  <v-card>
  </v-card>
</template>

<script>
export default {
  data: () => ({

  }),
}
</script>
