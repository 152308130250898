<template>
  <v-form v-model="valid" @submit.prevent="submit">
    <v-row>
      <v-col cols="12" md="6" lg="6">
        <v-text-field
          v-model="editedItem.leaveMessageName"
          :rules="[rules.required]"
          type="text"
          label="姓名"
          hint="请输入您的姓名"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <v-text-field
          v-model="editedItem.leaveMessagePhone"
          :rules="[rules.required, rules.phonenum]"
          type="text"
          label="电话"
          hint="请输入联系电话"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <v-text-field
          v-model="editedItem.enterpriseName"
          :rules="[rules.required]"
          type="text"
          label="公司"
          hint="请输入您的公司"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <v-text-field
          v-model="editedItem.leaveMessageEmail"
          :rules="[rules.required, rules.email]"
          type="text"
          label="邮箱"
          hint="请输入电子邮箱"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12" lg="12">
        <v-textarea
          v-model="editedItem.description"
          :rules="[rules.required]"
          type="text"
          label="需求"
          hint="请输入您的需求"
          outlined
        ></v-textarea>
      </v-col>
      <v-col cols="0" md="4" lg="4"></v-col>
      <v-col cols="12" md="4" lg="4">
        <v-btn depressed rounded x-large block color="primary" :disabled="!valid" @click="submit">
          提交
        </v-btn>
      </v-col>
      <v-col cols="0" md="4" lg="4"></v-col>
    </v-row>
  </v-form>
</template>
<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  PUBLISH_CONTACTUS
} from "@/store/actions.type";
import store from "@/store";
export default {
  props: {
    type: {
      type: String,
      default: 'BOTTOM_OF_HOMEPAGE'
    }
  },
  data: () => ({
    rules: {
      required: value => (value === 0 || !!value) || '请输入内容.',
      phonenum: value => {
        if (value) {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        } else {
          return true;
        }
      },
      selected: value => {
        if (!value || value.length < 1) {
          return '请至少选择一个选项。'
        } else {
          return true;
        }
      },
      email: value => {
        if (value) {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        } else {
          return true;
        }
      },
    },
    valid: true,
    editedItem: {
      leaveMessageName: '',
      leaveMessagePhone: '',
      enterpriseName: '',
      leaveMessageEmail: '',
      description: ''
    },
  }),
  methods: {
    submit() {
      this.editedItem.type = this.type;
      store.dispatch(PUBLISH_CONTACTUS, this.editedItem)
        .then(() => {
          this.editedItem = {};
          this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    }
  }
}
</script>
