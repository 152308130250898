import { ProductService } from "@/common/http";

import {
  FETCH_PROD_FILTER_LIST,
  FETCH_PROD_PRICE_LIMIT,
  FETCH_PROD_LIST,
  FETCH_PROD,
  FETCH_PROD_BRAND,
} from "./actions.type";

import {
  SET_ERROR,
  SET_PROD_FILTER_LIST,
  SET_PROD_PRICE_LIMIT,
  SET_PROD_LIST,
  SET_PROD,
  SET_PROD_BRAND
} from "./mutations.type"

const state = {
  filter_list: [],
  price_limit: [],
  product_list: [],
  product_info: {},
  brand_info: {}
};

const getters = {
  filter_list: state => state.filter_list,
  price_limit: state => state.price_limit,
  product_list: state => state.product_list,
  product_info: state => state.product_info,
  brand_info: state => state.brand_info
};

const actions = {
  [FETCH_PROD_FILTER_LIST]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      ProductService.get('attributes/row/findAttrNameByCategoryId/'+ slug)
        .then(({ data }) => {
          commit(SET_PROD_FILTER_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_PROD_PRICE_LIMIT]({ commit }, data) {
    return new Promise((resolve, reject) => {
      ProductService.post('customer/product/row/listPrice', data)
        .then(({ data }) => {
          commit(SET_PROD_PRICE_LIMIT, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_PROD_LIST]({ commit }, data) {
    return new Promise((resolve, reject) => {
      ProductService.post('customer/product/row/list', data)
        .then(({ data }) => {
          commit(SET_PROD_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_PROD]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      ProductService.get('customer/product/row/info/'+ slug)
        .then(({ data }) => {
          commit(SET_PROD, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_PROD_BRAND]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      ProductService.get('brand/row/info/'+ slug)
        .then(({ data }) => {
          commit(SET_PROD_BRAND, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
}

const mutations = {
  [SET_PROD_FILTER_LIST](state, data) {
    state.filter_list = data.result;
  },
  [SET_PROD_PRICE_LIMIT](state, data) {
    state.price_limit = data.result;
  },
  [SET_PROD_LIST](state, data) {
    state.product_list = data.result?.records;
  },
  [SET_PROD](state, data) {
    state.product_info = data.result;
  },
  [SET_PROD_BRAND](state, data) {
    state.brand_info = data.result;
  },
}

export default {
  state,
  actions,
  mutations,
  getters
};
