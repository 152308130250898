import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css'
import zhHans from 'vuetify/es5/locale/zh-Hans'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import iconApple from '@/components/icon/AppleLogo.vue'

Vue.use(Vuetify)

const opts = {
  theme: {
    dark: localStorage.getItem('aapl_dark') === 'true',
    themes: {
      light: {
        primary: '#0071e3',
        secondary: '#515154',
        accent: '#9b0020',
        error: '#F44336',
        info: '#6d6d6d',
        success: '#424242',
        warning: '#FFC107',
      },
      dark: {
        primary: '#0071e3',
        secondary: '#FF1744',
        accent: '#EF9A9A',
        error: '#EF9A9A',
        info: '#6d6d6d',
        success: '#6d6d6d',
        warning: '#FFC107',
      },
    },
  },
  icons: {
    iconfont: 'md',
    values: {
      apple: iconApple
    }
  },
  lang: {
    locales: { zhHans },
    current: 'zhHans',
  },
}

export default new Vuetify(opts)

