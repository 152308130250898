var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('core-head'), _c('core-topbar'), _c('core-drawer'), _c('core-view'), _c('core-footer'), _c('contact-fab'), _c('widgets-error'), _c('widgets-update-message')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }