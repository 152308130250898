import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { CHECK_AUTH } from '@/store/actions.type'
import { SET_ERROR } from "@/store/mutations.type";
import { writeLog } from '@/common/operationlog'
import ApiService from "@/common/http";


NProgress.configure({ showSpinner: false });

router.beforeEach(async(to, from, next) => {
  NProgress.start()
  //用户埋点：访问记录
  writeLog('visit', to.path, from, to)
  ApiService.setHeader();
  const hasToken = store.getters.isAuthenticated;

  if (to.matched.some(res => res.meta.login_required)) {
    if (hasToken) {
      store.dispatch(CHECK_AUTH).then(() => {
        next()
        NProgress.done()
      }).catch(err => {
        store.commit(SET_ERROR, {msg: '登陆错误：' + err.message, color: 'error'})
        NProgress.done()
      })
    } else {
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  } else {
    next()
    NProgress.done()
  }
})

router.afterEach(() => {
  NProgress.done(); // finish progress bar
});

