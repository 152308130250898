var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "姓名",
      "hint": "请输入您的姓名",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.leaveMessageName,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "leaveMessageName", $$v);
      },
      expression: "editedItem.leaveMessageName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.phonenum],
      "type": "text",
      "label": "电话",
      "hint": "请输入联系电话",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.leaveMessagePhone,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "leaveMessagePhone", $$v);
      },
      expression: "editedItem.leaveMessagePhone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "公司",
      "hint": "请输入您的公司",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.enterpriseName,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "enterpriseName", $$v);
      },
      expression: "editedItem.enterpriseName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.email],
      "type": "text",
      "label": "邮箱",
      "hint": "请输入电子邮箱",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.leaveMessageEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "leaveMessageEmail", $$v);
      },
      expression: "editedItem.leaveMessageEmail"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "需求",
      "hint": "请输入您的需求",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.description,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "description", $$v);
      },
      expression: "editedItem.description"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "0",
      "md": "4",
      "lg": "4"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "4"
    }
  }, [_c('v-btn', {
    attrs: {
      "depressed": "",
      "rounded": "",
      "x-large": "",
      "block": "",
      "color": "primary",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" 提交 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "0",
      "md": "4",
      "lg": "4"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }