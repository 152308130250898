var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "content-class": "my-custom-dialog",
      "width": _vm.$vuetify.breakpoint.mobile ? '' : '700',
      "fullscreen": _vm.$vuetify.breakpoint.mobile
    },
    on: {
      "click:outside": _vm.handleClose
    },
    model: {
      value: _vm.showDialog,
      callback: function callback($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mb-8"
  }, [_c('span', {
    staticClass: "text-center",
    staticStyle: {
      "margin": "auto"
    }
  }, [_vm._v(" 请告诉我们您的业务需求"), _c('br'), _vm._v(" 400-815-8815 ｜ info@doublerise.com ")])]), _c('v-card-text', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.validForm,
      callback: function callback($$v) {
        _vm.validForm = $$v;
      },
      expression: "validForm"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "姓名",
      "hint": "请输入您的姓名",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.leaveMessageName,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "leaveMessageName", $$v);
      },
      expression: "editedItem.leaveMessageName"
    }
  })], 1), _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.phonenum],
      "type": "text",
      "label": "电话",
      "hint": "请输入联系电话",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.leaveMessagePhone,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "leaveMessagePhone", $$v);
      },
      expression: "editedItem.leaveMessagePhone"
    }
  })], 1), _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mb-0 pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "公司",
      "hint": "请输入您的公司",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.enterpriseName,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "enterpriseName", $$v);
      },
      expression: "editedItem.enterpriseName"
    }
  })], 1), _c('v-col', {
    staticClass: "my-0 pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.email],
      "type": "text",
      "label": "邮箱",
      "hint": "请输入电子邮箱",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.leaveMessageEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "leaveMessageEmail", $$v);
      },
      expression: "editedItem.leaveMessageEmail"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "my-0 py-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-textarea', {
    staticClass: "contactTextArea",
    attrs: {
      "type": "text",
      "label": "需求",
      "rows": "3",
      "row-height": "30px",
      "outlined": "",
      "dense": "",
      "height": "106"
    },
    model: {
      value: _vm.editedItem.description,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "description", $$v);
      },
      expression: "editedItem.description"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-0 pt-0 text-center",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提交")]), _vm.$vuetify.breakpoint.mobile ? _c('v-btn', {
    staticClass: "ml-4",
    on: {
      "click": _vm.handleClose
    }
  }, [_vm._v("取消")]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }