var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "content-class": "my-custom-dialog",
      "width": _vm.$vuetify.breakpoint.mobile ? '' : '600',
      "height": _vm.$vuetify.breakpoint.mobile ? '' : '800',
      "scrollable": "",
      "fullscreen": _vm.$vuetify.breakpoint.mobile
    },
    model: {
      value: _vm.dialogVisible,
      callback: function callback($$v) {
        _vm.dialogVisible = $$v;
      },
      expression: "dialogVisible"
    }
  }, [_c('v-card', {
    staticClass: "aiCard"
  }, [_c('v-card-text', {
    staticClass: "closePanel"
  }, [_c('v-btn', {
    staticClass: "closeBtn",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.handleClose
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('iframe', {
    staticClass: "aiFrame",
    staticStyle: {
      "width": "100%",
      "border": "0px",
      "margin": "0"
    },
    attrs: {
      "id": "frame",
      "src": _vm.aiUrl
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }